export default {
    data() {
        return {
            // need to update in utils.js too in case it is modified
            ANNOUNCEMENT_BAR_HEIGHT: 3, // rem
        };
    },
    computed: {
        announcementsCombinedHeight() {
            return (
                this.$store.getters['data/getPublicAnnouncementsToDisplay'].length * this.ANNOUNCEMENT_BAR_HEIGHT +
                'rem'
            );
        },
        stickyElementTopPosition() {
            return (
                this.$store.getters['data/getPublicAnnouncementsToDisplay'].length * this.ANNOUNCEMENT_BAR_HEIGHT +
                66 / 16 +
                'rem'
            );
        },
    },
};
