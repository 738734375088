<template>
    <div class="flex flex-wrap items-center" :class="{ 'mt-4 lg:mt-6': isOnPageTop }">
        <div class="flex items-center breadcrumb-item"></div>
        <NuxtLink :to="localePath('index')" class="mr-1" :aria-label="$t('navigation.homepage')">
            <i class="pi pi-home"></i>
        </NuxtLink>
        <i class="pi pi-chevron-right mx-3 text-sm"></i>
        <template v-for="(item, i) in processedItems.filter((x) => !x.hidden)">
            <i v-if="i !== 0" :key="`breadcrumb-item-chevron-${i}`" class="pi pi-chevron-right mx-3 text-sm"></i>
            <span v-if="item.disabled" :key="`breadcrumb-item-link-${i}`" class="breadcrumb-item pointer-events-none">
                {{ item.label }}
            </span>
            <NuxtLink
                v-else
                :key="`breadcrumb-item-link-${i}`"
                :to="localePath({ name: item.routeName, params: $route.params })"
                class="breadcrumb-item last:pointer-events-none"
            >
                {{ item.label }}
            </NuxtLink>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumb',
    props: {
        // the items prop is an array of arrays, containing:
        //      - the page's name
        //      - route name segment for localePath()
        //      - boolean to decide whether the breadcrumb segment should be disabled (optional)
        //
        // *******************************************************************
        //
        // EXAMPLE 1
        // Input
        // [['Top helyek', 'top-locations', true], ['Magyar Zene Háza', 'house-of-hungarian-music']]
        //
        // Output
        // [
        //      {label: 'Hírek', routeName: 'top-locations', disabled: true},
        //      {label: 'Magyar Zene Háza', routeName: 'top-locations-house-of-hungarian-music', disabled: false},
        // ]
        //
        // ----------------
        //
        // EXAMPLE 2
        // Input
        // [['Hírek', 'news'], ['Fesztiválok', 'category'], ['Cikk címe', 'article']]
        //
        // Output
        // [
        //      {label: 'Hírek', routeName: 'news', disabled: false},
        //      {label: 'Fesztiválok', routeName: 'news-category', disabled: false},
        //      {label: 'Cikk címe', routeName: 'news-category-article', disabled: false},
        // ]
        //
        //
        //
        //
        items: {
            type: Array,
            required: true,
        },
        isOnPageTop: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            processedItems: [],
        };
    },
    watch: {
        '$route.path'() {
            this.processedItems = this.getProcessedBreadcrumbItems(...this.items);
        },
    },
    mounted() {
        this.processedItems = this.getProcessedBreadcrumbItems(...this.items);
    },
    methods: {
        getProcessedBreadcrumbItems(...params) {
            const breadcrumbData = [];

            params.forEach((breadcrumbEntityArray) => {
                const breadcrumbItem = {};
                let pathBase = '';

                if (breadcrumbData.length) {
                    pathBase += breadcrumbData[breadcrumbData.length - 1].routeName;
                    pathBase += '-';
                }

                breadcrumbItem.label = breadcrumbEntityArray[0];
                breadcrumbItem.routeName = pathBase + breadcrumbEntityArray[1];
                breadcrumbItem.disabled = !!breadcrumbEntityArray[2];
                breadcrumbItem.hidden = !!breadcrumbEntityArray[3];

                breadcrumbData.push(breadcrumbItem);
            });

            return breadcrumbData;
        },
    },
};
</script>

<style scoped>
.breadcrumb-item {
    @apply font-Poppins mr-1 text-sm uppercase;
}
</style>
