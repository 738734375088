import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2b24a237 = () => interopDefault(import('../pages/the-project/index.vue' /* webpackChunkName: "pages/the-project/index" */))
const _165ace20 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7686fa7c = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _d6a522ee = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _14b12212 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _62eecc1a = () => interopDefault(import('../pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _a68187fe = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _29e2da64 = () => interopDefault(import('../pages/public-information/index.vue' /* webpackChunkName: "pages/public-information/index" */))
const _8ea69072 = () => interopDefault(import('../pages/visitor-information/index.vue' /* webpackChunkName: "pages/visitor-information/index" */))
const _57301070 = () => interopDefault(import('../pages/magazine/index.vue' /* webpackChunkName: "pages/magazine/index" */))
const _5d9ecc7d = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _4ee9b59c = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _6f7ddc5a = () => interopDefault(import('../pages/map.vue' /* webpackChunkName: "pages/map" */))
const _054ff8dc = () => interopDefault(import('../pages/business-partners/index.vue' /* webpackChunkName: "pages/business-partners/index" */))
const _c3e2ad98 = () => interopDefault(import('../pages/programs-in-varosliget/index.vue' /* webpackChunkName: "pages/programs-in-varosliget/index" */))
const _0995aeb1 = () => interopDefault(import('../pages/the-project/our-first-10-years.vue' /* webpackChunkName: "pages/the-project/our-first-10-years" */))
const _2b31d8e3 = () => interopDefault(import('../pages/the-project/swiper.vue' /* webpackChunkName: "pages/the-project/swiper" */))
const _5b812940 = () => interopDefault(import('../pages/press/media-library/index.vue' /* webpackChunkName: "pages/press/media-library/index" */))
const _3fe85d3a = () => interopDefault(import('../pages/top-locations/balloon-fly.vue' /* webpackChunkName: "pages/top-locations/balloon-fly" */))
const _b0cddb72 = () => interopDefault(import('../pages/top-locations/bird-friendly-learning-trail.vue' /* webpackChunkName: "pages/top-locations/bird-friendly-learning-trail" */))
const _4fb6e350 = () => interopDefault(import('../pages/top-locations/house-of-hungarian-music.vue' /* webpackChunkName: "pages/top-locations/house-of-hungarian-music" */))
const _6e4f0899 = () => interopDefault(import('../pages/top-locations/house-of-the-hungarian-millennium.vue' /* webpackChunkName: "pages/top-locations/house-of-the-hungarian-millennium" */))
const _222ac398 = () => interopDefault(import('../pages/top-locations/mocsenyi-mihaly-botanical-garden.vue' /* webpackChunkName: "pages/top-locations/mocsenyi-mihaly-botanical-garden" */))
const _53ea5b90 = () => interopDefault(import('../pages/top-locations/museum-of-ethnography.vue' /* webpackChunkName: "pages/top-locations/museum-of-ethnography" */))
const _5deffe16 = () => interopDefault(import('../pages/top-locations/museum-of-fine-arts.vue' /* webpackChunkName: "pages/top-locations/museum-of-fine-arts" */))
const _74035bd7 = () => interopDefault(import('../pages/top-locations/city-park-visitor-center.vue' /* webpackChunkName: "pages/top-locations/city-park-visitor-center" */))
const _555b2f54 = () => interopDefault(import('../pages/top-locations/playground-of-varosliget.vue' /* webpackChunkName: "pages/top-locations/playground-of-varosliget" */))
const _4cf7b41c = () => interopDefault(import('../pages/top-locations/varosliget-promenade.vue' /* webpackChunkName: "pages/top-locations/varosliget-promenade" */))
const _4cfb9dd6 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/index.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/index" */))
const _42276782 = () => interopDefault(import('../pages/press/media-library/_gallerycategory/_gallery.vue' /* webpackChunkName: "pages/press/media-library/_gallerycategory/_gallery" */))
const _207fc97d = () => interopDefault(import('../pages/archive/_article.vue' /* webpackChunkName: "pages/archive/_article" */))
const _43f260c7 = () => interopDefault(import('../pages/business-partners/_partnerInfo.vue' /* webpackChunkName: "pages/business-partners/_partnerInfo" */))
const _13296a60 = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _6be6b2ca = () => interopDefault(import('../pages/public-information/_data.vue' /* webpackChunkName: "pages/public-information/_data" */))
const _1d317f09 = () => interopDefault(import('../pages/magazine/_article.vue' /* webpackChunkName: "pages/magazine/_article" */))
const _ed1cd326 = () => interopDefault(import('../pages/press/_release.vue' /* webpackChunkName: "pages/press/_release" */))
const _1b088462 = () => interopDefault(import('../pages/program-series/_programSeries.vue' /* webpackChunkName: "pages/program-series/_programSeries" */))
const _27588e9c = () => interopDefault(import('../pages/programs/_program.vue' /* webpackChunkName: "pages/programs/_program" */))
const _2e377885 = () => interopDefault(import('../pages/the-project/_project.vue' /* webpackChunkName: "pages/the-project/_project" */))
const _7e6a5932 = () => interopDefault(import('../pages/visitor-information/_visitorInformation.vue' /* webpackChunkName: "pages/visitor-information/_visitorInformation" */))
const _da12657e = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-projekt",
    component: _2b24a237,
    name: "the-project___hu"
  }, {
    path: "/adatvedelem",
    component: _165ace20,
    name: "privacy-policy___hu"
  }, {
    path: "/archivum",
    component: _7686fa7c,
    name: "archive___hu"
  }, {
    path: "/en",
    component: _d6a522ee,
    name: "index___en"
  }, {
    path: "/hirlevel-feliratkozas",
    component: _14b12212,
    name: "newsletter___hu"
  }, {
    path: "/karrier",
    component: _62eecc1a,
    name: "career___hu"
  }, {
    path: "/kereses",
    component: _a68187fe,
    name: "search___hu"
  }, {
    path: "/kozerdeku-adatok",
    component: _29e2da64,
    name: "public-information___hu"
  }, {
    path: "/latogatas",
    component: _8ea69072,
    name: "visitor-information___hu"
  }, {
    path: "/magazin",
    component: _57301070,
    name: "magazine___hu"
  }, {
    path: "/sajto",
    component: _5d9ecc7d,
    name: "press___hu"
  }, {
    path: "/szakmai-anyagok",
    component: _4ee9b59c,
    name: "documents___hu"
  }, {
    path: "/terkep",
    component: _6f7ddc5a,
    name: "map___hu"
  }, {
    path: "/uzleti-partnereinknek",
    component: _054ff8dc,
    name: "business-partners___hu"
  }, {
    path: "/varosligeti-programok",
    component: _c3e2ad98,
    name: "programs-in-varosliget___hu"
  }, {
    path: "/en/archive",
    component: _7686fa7c,
    name: "archive___en"
  }, {
    path: "/en/business-partners",
    component: _054ff8dc,
    name: "business-partners___en"
  }, {
    path: "/en/career",
    component: _62eecc1a,
    name: "career___en"
  }, {
    path: "/en/hirlevel-feliratkozas",
    component: _14b12212,
    name: "newsletter___en"
  }, {
    path: "/en/kozerdeku-adatok",
    component: _29e2da64,
    name: "public-information___en"
  }, {
    path: "/en/magazine",
    component: _57301070,
    name: "magazine___en"
  }, {
    path: "/en/map",
    component: _6f7ddc5a,
    name: "map___en"
  }, {
    path: "/en/press",
    component: _5d9ecc7d,
    name: "press___en"
  }, {
    path: "/en/privacy-policy",
    component: _165ace20,
    name: "privacy-policy___en"
  }, {
    path: "/en/programs-in-varosliget",
    component: _c3e2ad98,
    name: "programs-in-varosliget___en"
  }, {
    path: "/en/search",
    component: _a68187fe,
    name: "search___en"
  }, {
    path: "/en/szakmai-anyagok",
    component: _4ee9b59c,
    name: "documents___en"
  }, {
    path: "/en/the-project",
    component: _2b24a237,
    name: "the-project___en"
  }, {
    path: "/en/visitor-information",
    component: _8ea69072,
    name: "visitor-information___en"
  }, {
    path: "/projekt/a-liget-projekt-elso-10-eve",
    component: _0995aeb1,
    name: "the-project-our-first-10-years___hu"
  }, {
    path: "/projekt/swiper",
    component: _2b31d8e3,
    name: "the-project-swiper___hu"
  }, {
    path: "/sajto/mediatar",
    component: _5b812940,
    name: "press-media-library___hu"
  }, {
    path: "/top-helyek/ballon-kilato",
    component: _3fe85d3a,
    name: "top-locations-balloon-fly___hu"
  }, {
    path: "/top-helyek/madarbarat-tanosveny",
    component: _b0cddb72,
    name: "top-locations-bird-friendly-learning-trail___hu"
  }, {
    path: "/top-helyek/magyar-zene-haza",
    component: _4fb6e350,
    name: "top-locations-house-of-hungarian-music___hu"
  }, {
    path: "/top-helyek/millennium-haza-neo-kortars-muveszeti-ter",
    component: _6e4f0899,
    name: "top-locations-house-of-the-hungarian-millennium___hu"
  }, {
    path: "/top-helyek/mocsenyi-mihaly-botanikus-kert",
    component: _222ac398,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___hu"
  }, {
    path: "/top-helyek/neprajzi-muzeum",
    component: _53ea5b90,
    name: "top-locations-museum-of-ethnography___hu"
  }, {
    path: "/top-helyek/szepmuveszeti-muzeum",
    component: _5deffe16,
    name: "top-locations-museum-of-fine-arts___hu"
  }, {
    path: "/top-helyek/varosliget-latogatokozpont",
    component: _74035bd7,
    name: "top-locations-city-park-visitor-center___hu"
  }, {
    path: "/top-helyek/varosligeti-nagyjatszoter",
    component: _555b2f54,
    name: "top-locations-playground-of-varosliget___hu"
  }, {
    path: "/top-helyek/varosligeti-promenad",
    component: _4cf7b41c,
    name: "top-locations-varosliget-promenade___hu"
  }, {
    path: "/en/press/media",
    component: _5b812940,
    name: "press-media-library___en"
  }, {
    path: "/en/the-project/our-first-10-years",
    component: _0995aeb1,
    name: "the-project-our-first-10-years___en"
  }, {
    path: "/en/the-project/swiper",
    component: _2b31d8e3,
    name: "the-project-swiper___en"
  }, {
    path: "/en/top-locations/balloon-fly",
    component: _3fe85d3a,
    name: "top-locations-balloon-fly___en"
  }, {
    path: "/en/top-locations/bird-friendly-learning-trail",
    component: _b0cddb72,
    name: "top-locations-bird-friendly-learning-trail___en"
  }, {
    path: "/en/top-locations/city-park-visitor-center",
    component: _74035bd7,
    name: "top-locations-city-park-visitor-center___en"
  }, {
    path: "/en/top-locations/house-of-hungarian-music",
    component: _4fb6e350,
    name: "top-locations-house-of-hungarian-music___en"
  }, {
    path: "/en/top-locations/house-of-the-hungarian-millennium",
    component: _6e4f0899,
    name: "top-locations-house-of-the-hungarian-millennium___en"
  }, {
    path: "/en/top-locations/mocsenyi-mihaly-botanical-garden",
    component: _222ac398,
    name: "top-locations-mocsenyi-mihaly-botanical-garden___en"
  }, {
    path: "/en/top-locations/museum-of-ethnography",
    component: _53ea5b90,
    name: "top-locations-museum-of-ethnography___en"
  }, {
    path: "/en/top-locations/museum-of-fine-arts",
    component: _5deffe16,
    name: "top-locations-museum-of-fine-arts___en"
  }, {
    path: "/en/top-locations/playground-of-varosliget",
    component: _555b2f54,
    name: "top-locations-playground-of-varosliget___en"
  }, {
    path: "/en/top-locations/varosliget-promenade",
    component: _4cf7b41c,
    name: "top-locations-varosliget-promenade___en"
  }, {
    path: "/en/press/media/:gallerycategory",
    component: _4cfb9dd6,
    name: "press-media-library-gallerycategory___en"
  }, {
    path: "/en/press/media/:gallerycategory/:gallery",
    component: _42276782,
    name: "press-media-library-gallerycategory-gallery___en"
  }, {
    path: "/en/archive/:article",
    component: _207fc97d,
    name: "archive-article___en"
  }, {
    path: "/en/business-partners/:partnerInfo",
    component: _43f260c7,
    name: "business-partners-partnerInfo___en"
  }, {
    path: "/en/career/:position",
    component: _13296a60,
    name: "career-position___en"
  }, {
    path: "/en/kozerdeku-adatok/:data",
    component: _6be6b2ca,
    name: "public-information-data___en"
  }, {
    path: "/en/magazine/:article",
    component: _1d317f09,
    name: "magazine-article___en"
  }, {
    path: "/en/press/:release",
    component: _ed1cd326,
    name: "press-release___en"
  }, {
    path: "/en/program-series/:programSeries",
    component: _1b088462,
    name: "program-series-programSeries___en"
  }, {
    path: "/en/programs/:program",
    component: _27588e9c,
    name: "programs-program___en"
  }, {
    path: "/en/the-project/:project",
    component: _2e377885,
    name: "the-project-project___en"
  }, {
    path: "/en/visitor-information/:visitorInformation",
    component: _7e6a5932,
    name: "visitor-information-visitorInformation___en"
  }, {
    path: "/sajto/mediatar/:gallerycategory",
    component: _4cfb9dd6,
    name: "press-media-library-gallerycategory___hu"
  }, {
    path: "/sajto/mediatar/:gallerycategory/:gallery",
    component: _42276782,
    name: "press-media-library-gallerycategory-gallery___hu"
  }, {
    path: "/archivum/:article",
    component: _207fc97d,
    name: "archive-article___hu"
  }, {
    path: "/en/:slug",
    component: _da12657e,
    name: "slug___en"
  }, {
    path: "/karrier/:position",
    component: _13296a60,
    name: "career-position___hu"
  }, {
    path: "/kozerdeku-adatok/:data",
    component: _6be6b2ca,
    name: "public-information-data___hu"
  }, {
    path: "/latogatas/:visitorInformation",
    component: _7e6a5932,
    name: "visitor-information-visitorInformation___hu"
  }, {
    path: "/magazin/:article",
    component: _1d317f09,
    name: "magazine-article___hu"
  }, {
    path: "/programok/:program",
    component: _27588e9c,
    name: "programs-program___hu"
  }, {
    path: "/programsorozatok/:programSeries",
    component: _1b088462,
    name: "program-series-programSeries___hu"
  }, {
    path: "/projekt/:project",
    component: _2e377885,
    name: "the-project-project___hu"
  }, {
    path: "/sajto/:release",
    component: _ed1cd326,
    name: "press-release___hu"
  }, {
    path: "/uzleti-partnereinknek/:partnerInfo",
    component: _43f260c7,
    name: "business-partners-partnerInfo___hu"
  }, {
    path: "/",
    component: _d6a522ee,
    name: "index___hu"
  }, {
    path: "/:slug",
    component: _da12657e,
    name: "slug___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
