<template>
    <div>
        <button
            v-for="(locale, i) in $i18n.availableLocales"
            :key="`locale-${locale}`"
            class="uppercase items-center relative font-Poppins"
            :class="{
                'font-bold': $i18n.locale === locale,
                'pr-2 mr-2': i < $i18n.availableLocales.length - 1,
                'text-white': themeDark,
            }"
            @click="$i18n.setLocale($i18n.locale === 'hu' ? 'en' : 'hu')"
        >
            {{ locale }}
            <span
                v-if="i < $i18n.availableLocales.length - 1"
                class="absolute h-[calc(100%-0.5rem)] w-[1px] top-1 right-0"
                :class="themeDark ? 'bg-white' : 'bg-black'"
            ></span>
        </button>
    </div>
</template>

<script>
export default {
    name: 'LanguageSwitcher',
    props: {
        themeDark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
