<template>
    <button
        class="fixed bottom-8 right-8 transition-all bg-yellow-400 hover:bg-yellow-500 w-10 h-10 rounded-full shadow-md"
        :class="$store.state.scrollY > 700 ? 'opacity-100' : 'opacity-0'"
        aria-label="scroll to top"
        @click="scrollToTop"
    >
        <i class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pi pi-chevron-up"></i>
    </button>
</template>

<script>
export default {
    name: 'ScrollToTopButton',
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style scoped></style>
