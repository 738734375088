<template>
    <button v-if="btnClose" class="btn-close p-2 bg-white rounded-full inline-flex items-center justify-center">
        <i class="pi pi-times font-bold text-black"></i>
    </button>
    <a
        v-else-if="to && shouldUseAnchorTag"
        :href="to"
        :target="target"
        rel="noopener noreferrer"
        class="p-button p-component"
    >
        <span v-if="icon" :class="`p-button-icon ${icon} p-button-icon-${iconPos}`"></span>
        <span class="p-button-label">
            {{ label }}
        </span>
    </a>
    <NuxtLink v-else-if="to" :to="to" class="p-button p-component">
        <span v-if="icon" :class="`p-button-icon ${icon} p-button-icon-${iconPos}`"></span>
        <span class="p-button-label">
            {{ label }}
        </span>
    </NuxtLink>
    <Button v-else :label="label" :disabled="disabled" :icon="icon" :icon-pos="iconPos" />
</template>

<script>
import Button from 'primevue/button';
export default {
    name: 'CustomButton',
    components: {
        Button,
    },
    props: {
        to: {
            type: [String, Object],
            required: false,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        target: {
            type: String,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: null,
        },
        iconPos: {
            type: String,
            required: false,
            default: 'right',
        },
        btnClose: {
            type: Boolean,
            required: false,
            default: null,
        },
    },
    computed: {
        shouldUseAnchorTag() {
            if (this.target === '_blank') {
                return true;
            }

            return typeof this.to === 'string' && /https?:\/\/[\w\-.]+/.test(this.to);
        },
    },
};
</script>

<style>
.btn-close.p-button-sm {
    padding: 0 !important;
    position: relative;
    @apply w-6 h-6;
    i {
        @apply text-sm absolute top-1/2 left-1/2;
        width: 14px;
        height: 14px;
        line-height: 14px;
        transform: translate(-50%, -50%);
    }
}
</style>
