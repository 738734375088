<template>
    <div class="mb-4 w-full bg-gray-600" style="height: 1px"></div>
</template>

<script>
export default {
    name: 'SeparatorLine',
};
</script>

<style scoped></style>
