<template>
    <svg width="242" height="33" viewBox="0 0 242 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="ligetlogo_header" clip-path="url(#clip0_236_4835)">
            <path
                id="Vector"
                d="M0 23.6744C3.05204 24.7488 4.78906 26.7039 4.78906 30.9094V32.7145H7.36514V31.8531C7.36514 29.4624 7.12962 27.8073 6.53098 26.6023C5.41223 24.3471 3.27776 22.6727 0.711489 22.1597L0 23.6744Z"
                :fill="fillColor"
            />
            <path id="Vector_2" d="M57.5921 32.729V14.0053H54.5156H53.9316V31.4611V32.729H57.5921Z" :fill="fillColor" />
            <path
                id="Vector_3"
                d="M70.8051 32.9952C74.2742 32.9952 77.0956 31.524 78.7787 29.414V21.095H70.8689V24.3036H75.1182V28.3445C74.0338 29.2011 72.6206 29.7866 70.8885 29.7866C67.228 29.7866 64.4066 27.004 64.4066 23.3696C64.4066 19.7352 67.1201 16.9525 70.7266 16.9525C72.5961 16.9525 74.1957 17.751 75.3341 18.8786L77.6943 16.4153C75.9867 14.7844 73.5725 13.7391 70.7511 13.7391C64.8384 13.7391 60.7461 17.993 60.7461 23.3696C60.7461 28.7462 64.8924 32.9952 70.8051 32.9952Z"
                :fill="fillColor"
            />
            <path
                id="Vector_4"
                d="M94.2553 29.5205H86.2523V24.3036H92.0865V21.095H86.2523V17.2138H93.9854V14.0053H82.5918V32.729H94.2553V29.5205Z"
                :fill="fillColor"
            />
            <path
                id="Vector_5"
                d="M96.7773 14.0053V17.2138H101.164V32.729H104.825V17.2138H109.211V14.0053H96.7773Z"
                :fill="fillColor"
            />
            <path
                id="Vector_6"
                d="M46.6 15.4378L45.0592 12.1711C44.1368 12.6115 43.3026 13.1971 42.5666 13.8843C40.7069 15.6362 39.5391 18.0946 39.5391 20.8289V22.4694V32.7242H50.5696V29.5156H43.1996V20.8337C43.1946 19.3867 43.7197 18.0607 44.5833 17.0202C45.1279 16.3669 45.8149 15.8249 46.5902 15.4426"
                :fill="fillColor"
            />
            <path
                id="Vector_7"
                d="M39.7984 9.3062L38.6846 7.56401L38.6748 7.54465C36.3587 9.54333 34.6364 12.1953 33.8268 15.22C33.4931 16.4734 33.3116 17.9107 33.3116 21.2015L33.2969 32.7193H36.1379V22.6049C36.1379 19.1931 36.0447 17.0251 36.7169 14.4941C37.2272 12.568 38.4196 10.7387 39.7984 9.30136"
                :fill="fillColor"
            />
            <path
                id="Vector_8"
                d="M11.7909 21.1579C10.6231 18.7963 8.74375 16.846 6.43754 15.5587L5.43164 17.1654C7.01164 18.1865 8.54747 19.7981 9.32275 21.6177C10.3679 24.0713 10.2747 26.941 10.2747 28.4897V32.7145H13.1108V28.6784C13.1108 25.4408 12.6349 22.8711 11.786 21.1531"
                :fill="fillColor"
            />
            <path
                id="Vector_9"
                d="M23.5725 14.4989C21.9189 8.67708 18.3958 3.62472 13.6902 0L12.748 1.71315C15.6676 4.1861 18.0278 7.28333 19.598 10.8064C20.9915 13.9375 21.7668 17.2864 21.7668 22.7066L21.7864 32.7193H24.6275V23.5486C24.6275 19.7739 24.2644 16.8993 23.5774 14.4941"
                :fill="fillColor"
            />
            <path
                id="Vector_10"
                d="M17.9646 18.1139C16.2325 12.6744 12.1599 8.26089 6.90956 6.01056L6.23242 7.62692C9.61322 9.40299 12.3709 12.1808 14.0981 15.5636C15.6045 18.5108 16.0363 21.4628 16.0363 25.8425V32.7242H18.8773V26.3361C18.8773 23.084 18.5584 19.9723 17.9695 18.1188"
                :fill="fillColor"
            />
            <path
                id="Vector_11"
                d="M28.592 7.76243C27.8609 5.39112 26.958 3.09723 25.8589 0.909813L24.3574 2.23581C25.6087 4.93621 26.5115 7.96569 27.0071 11.0581C27.4193 13.623 27.5321 16.8944 27.5321 23.0598V32.7193H30.3683C30.3683 32.7193 30.3683 30.8852 30.3732 28.63H30.3781V24.6472C30.3781 16.8751 29.9414 12.1373 28.5871 7.76243"
                :fill="fillColor"
            />
            <path
                id="Vector_12"
                d="M126.729 22.1161C128.682 22.9969 130.061 24.952 130.061 27.1975C130.061 28.8526 129.33 30.2996 128.191 31.3159C127.19 32.1966 125.86 32.7338 123.883 32.7338H117.053V14.015H123.26C125.021 14.015 126.135 14.4941 126.974 15.2732C127.867 16.1007 128.437 17.2767 128.437 18.5882C128.437 20.0062 127.759 21.2886 126.729 22.1161ZM123.176 21.637C124.614 21.637 125.429 21.2354 125.998 20.6498C126.513 20.1126 126.812 19.3915 126.812 18.593C126.812 17.7945 126.513 17.0686 125.998 16.5315C125.429 15.9459 124.614 15.5442 123.176 15.5442H118.677V21.6419H123.176V21.637ZM118.677 23.1615V31.2094H123.8C125.453 31.2094 126.454 30.7835 127.19 30.0867C127.951 29.3656 128.437 28.3203 128.437 27.1975C128.437 26.0748 127.951 25.0295 127.19 24.3084C126.459 23.6164 125.453 23.1566 123.8 23.1566H118.677V23.1615Z"
                :fill="fillColor"
            />
            <path
                id="Vector_13"
                d="M134.354 29.6027C133.647 28.509 133.24 27.2217 133.24 25.2472V14.0198H134.864V25.3295C134.864 26.9604 135.164 27.9476 135.703 28.8042C136.758 30.4351 138.657 31.4804 140.826 31.4804C142.995 31.4804 144.894 30.4351 145.949 28.8042C146.494 27.9476 146.788 26.9604 146.788 25.3295V14.0198H148.412V25.2472C148.412 27.2266 148.005 28.509 147.298 29.6027C145.969 31.6595 143.559 33 140.821 33C138.083 33 135.669 31.6643 134.344 29.6027"
                :fill="fillColor"
            />
            <path
                id="Vector_14"
                d="M163.467 30.9674C161.921 32.0902 159.944 32.7338 156.936 32.7338H151.514V14.015H156.936C159.944 14.015 161.926 14.6586 163.467 15.7813C165.797 17.4945 167.235 20.2723 167.235 23.3744C167.235 26.4765 165.797 29.2543 163.467 30.9674ZM162.628 17.117C161.273 16.0765 159.512 15.5394 156.852 15.5394H153.138V31.2046H156.852C159.512 31.2046 161.273 30.6722 162.628 29.6269C164.497 28.1848 165.606 25.886 165.606 23.3696C165.606 20.8531 164.497 18.5592 162.628 17.1122"
                :fill="fillColor"
            />
            <path
                id="Vector_15"
                d="M175.777 14.015L167.588 32.729H169.212L171.705 27.0862H180.92L183.412 32.729H185.037L176.852 14.015H175.768H175.777ZM176.317 16.5266L180.247 25.5618H172.357L176.317 16.5266Z"
                :fill="fillColor"
            />
            <path
                id="Vector_16"
                d="M189.29 23.6938V32.729H187.666V14.015H194.688C196.425 14.015 197.563 14.4408 198.402 15.1377C199.462 16.0185 200.139 17.359 200.139 18.8544C200.139 20.3498 199.462 21.6854 198.402 22.571C197.563 23.2679 196.425 23.6938 194.688 23.6938H189.295H189.29ZM194.604 22.1694C196.067 22.1694 196.91 21.7967 197.529 21.1821C198.127 20.5917 198.505 19.7642 198.505 18.8544C198.505 17.9446 198.127 17.117 197.529 16.5266C196.906 15.912 196.067 15.5394 194.604 15.5394H189.29V22.1694H194.604Z"
                :fill="fillColor"
            />
            <path
                id="Vector_17"
                d="M203.059 14.015V32.729H213.633V31.2046H204.688V23.1615H211.464V21.637H204.688V15.5394H213.358V14.015H203.059Z"
                :fill="fillColor"
            />
            <path
                id="Vector_18"
                d="M217.24 28.7994C218.349 30.2173 219.841 31.4756 222.334 31.4756C224.232 31.4756 226.239 30.1399 226.239 27.9718C226.239 24.5746 222.172 24.2019 219.409 22.5178C218.001 21.6612 216.833 20.5675 216.833 18.6705C216.833 15.7572 219.193 13.7488 222.226 13.7488C224.664 13.7488 226.21 14.8715 227.078 15.6216L226.048 16.7976C224.939 15.8346 223.855 15.2732 222.28 15.2732C220.165 15.2732 218.457 16.5557 218.457 18.5882C218.457 21.4241 222.28 21.7967 224.988 23.4518C226.558 24.4149 227.864 25.8038 227.864 27.9718C227.864 30.9384 225.425 33 222.334 33C219.811 33 217.672 31.9595 216.043 29.8447L217.235 28.7994H217.24Z"
                :fill="fillColor"
            />
            <path
                id="Vector_19"
                d="M229.139 14.015V15.5394H234.561V32.729H236.185V15.5394H241.607V14.015H229.139Z"
                :fill="fillColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_236_4835">
                <rect width="241.607" height="33" :fill="fillColor" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'LigetLogo',
    props: {
        white: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        fillColor() {
            return this.white ? 'white' : 'black';
        },
    },
};
</script>

<style scoped></style>
