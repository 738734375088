<template>
    <a :href="url" target="_blank" class="inline-block" :aria-label="type">
        <object
            class="inline-block"
            :class="small ? 'w-5 h-5' : 'w-7 h-7'"
            :aria-label="`${type} icon`"
            type="image/svg+xml"
            :data="require(`~/assets/images/icons/${type}.svg`)"
            :style="
                nonFilterable
                    ? 'filter: none'
                    : white
                    ? 'filter: invert(99%) sepia(24%) saturate(275%) hue-rotate(190deg) brightness(110%) contrast(101%)'
                    : ''
            "
        ></object>
    </a>
</template>

<script>
export default {
    name: 'SocialIcon',
    props: {
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['instagram', 'facebook', 'linkedin', 'youtube'].includes(value);
            },
        },
        white: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        url: {
            type: String,
            required: true,
        },
        nonFilterable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
