<template>
    <header
        ref="header"
        class="fixed left-0 w-full"
        :class="dark ? 'bg-black bg-theme-dark' : 'bg-white'"
        :style="`top: ${announcementsCombinedHeight}`"
    >
        {{/* * */}}
        {{/* MOBILE - logo & hamburger button */}}
        {{/* * */}}
        <div class="py-4 container flex justify-between lg:hidden relative z-2" :class="dark ? 'bg-black' : 'bg-white'">
            <NuxtLink :to="localePath('index')" :aria-label="$t('navigation.homepage')">
                <LigetLogo class="w-40 h-8" :white="dark" />
            </NuxtLink>
            <div class="flex items-center">
                <Button
                    class="p-button-secondary p-button-text"
                    :aria-label="$t('navigation.toggle_mobile_menu')"
                    :icon="isMobileMenuOpen ? 'pi pi-times' : 'pi pi-bars'"
                    @click.native="isMobileMenuOpen = !isMobileMenuOpen"
                />
            </div>
        </div>

        {{/* * */}}
        {{/* MOBILE & DESKTOP - the menu itself */}}
        {{/* * */}}
        <nav
            class="absolute lg:relative w-full bg-gray-200 text-black z-2 h-[calc(100vh-72px)] lg:h-auto overflow-auto lg:overflow-visible lg:translate-x-0 transition-transform"
            :class="{ 'translate-x-full': !isMobileMenuOpen, 'lg:bg-white': !dark, 'lg:bg-black': dark }"
        >
            <div class="container relative pt-4 lg:pt-0">
                {{/* MOBILE - searchbar */}}
                <form class="relative lg:hidden" @submit.prevent="search">
                    <InputText
                        v-model="searchQuery"
                        class="mobile-search force-bg-theme-light"
                        :placeholder="$t('generic.search')"
                    />
                    <div class="absolute top-1/2 right-0 -translate-y-1/2">
                        <Button
                            type="submit"
                            class="p-button-secondary p-button-text"
                            icon="pi pi-search"
                            :aria-label="$t('generic.search')"
                        />
                    </div>
                </form>

                <div
                    class="flex flex-col lg:flex-row lg:items-center lg:justify-center"
                    :class="{ 'transition-panels': doMenuPanelsHaveTransition }"
                >
                    <div class="lvl1-menu-group lvl1-menu-group--left">
                        <div
                            id="program"
                            ref="program"
                            class="lvl1-menu-item lg:mr-6 xl:mr-9"
                            :class="{ open: isLvl2PanelOpen.program }"
                        >
                            <button
                                data-id="program"
                                :class="{ 'nuxt-link-active': /^programs-in-varosliget___/.test($route.name) }"
                                @click="toggleMenuPanel"
                            >
                                <span><T t="navigation.programs" /></span> <i class="pi pi-chevron-down"></i>
                            </button>

                            <div class="lvl1-menu-item__panel">
                                <div class="lg:container grid grid-cols-12 base-responsive-gap pb-4 lg:pb-0">
                                    <div
                                        v-if="$store.state.data.eventCategories.length"
                                        class="col-span-12 lg:col-span-3 flex flex-col items-start"
                                    >
                                        <NuxtLink
                                            class="lvl2-menu-item"
                                            :to="
                                                localePath({
                                                    name: 'programs-in-varosliget',
                                                    query: {
                                                        category: EVENT_CATEGORIES.CULTURAL.slug,
                                                    },
                                                })
                                            "
                                        >
                                            <span><T t="navigation.category_1_programs" /></span>
                                        </NuxtLink>

                                        <NuxtLink
                                            class="lvl2-menu-item"
                                            :to="
                                                localePath({
                                                    name: 'programs-in-varosliget',
                                                    query: {
                                                        category: EVENT_CATEGORIES.OUTDOOR.slug,
                                                    },
                                                })
                                            "
                                        >
                                            <span><T t="navigation.category_2_programs" /></span>
                                        </NuxtLink>

                                        <NuxtLink
                                            class="lvl2-menu-item"
                                            :to="
                                                localePath({
                                                    name: 'programs-in-varosliget',
                                                    query: {
                                                        category: EVENT_CATEGORIES.FAMILY.slug,
                                                    },
                                                })
                                            "
                                        >
                                            <span><T t="navigation.category_3_programs" /></span>
                                        </NuxtLink>

                                        <NuxtLink
                                            class="lvl2-menu-item"
                                            :to="
                                                localePath({
                                                    name: 'programs-in-varosliget',
                                                    query: {
                                                        category: EVENT_CATEGORIES.SPORT.slug,
                                                    },
                                                })
                                            "
                                        >
                                            <span><T t="navigation.category_4_programs" /></span>
                                        </NuxtLink>

                                        <div class="border-t border-black mb-4 hidden lg:block"></div>

                                        <NuxtLink
                                            class="lvl2-menu-item"
                                            :to="
                                                localePath({
                                                    name: 'programs-in-varosliget',
                                                    query: {
                                                        category: EVENT_CATEGORIES.CULTURAL.slug,
                                                        from: $dayjs().format('YYYY-MM-DD'),
                                                        to: $dayjs().format('YYYY-MM-DD'),
                                                    },
                                                })
                                            "
                                        >
                                            <span><T t="navigation.programs_today" /></span>
                                        </NuxtLink>

                                        <NuxtLink
                                            class="lvl2-menu-item"
                                            :to="
                                                localePath({
                                                    name: 'programs-in-varosliget',
                                                    query: {
                                                        category: EVENT_CATEGORIES.CULTURAL.slug,
                                                        from: $dayjs()
                                                            .add(7 - $dayjs().day() - 1, 'day')
                                                            .format('YYYY-MM-DD'),
                                                        to: $dayjs()
                                                            .add(7 - $dayjs().day(), 'day')
                                                            .format('YYYY-MM-DD'),
                                                    },
                                                })
                                            "
                                        >
                                            <span><T t="navigation.programs_this_weekend" /></span>
                                        </NuxtLink>
                                    </div>
                                    <div
                                        v-if="$store.state.data.recommendedEvents.length"
                                        class="col-span-12 lg:col-span-8 lg:col-start-5 hidden lg:block"
                                    >
                                        <h2 class="mb-5 text-sm uppercase">
                                            <T t="navigation.featured_programs" />
                                        </h2>
                                        <div class="grid grid-cols-3 base-responsive-gap">
                                            <HeaderProgramCard
                                                v-for="event in $store.state.data.recommendedEvents.slice(0, 3)"
                                                :key="`upcoming-program-${event.id}`"
                                                :is-image-displayed="isLvl2PanelOpen.event || isMobileMenuOpen"
                                                :title="event.name"
                                                :url="$getEventUrl(event)"
                                                :date="$getFormattedEventInterval(event)"
                                                :img="$getMediaImage(event.media, 'lead', 'sm')"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            id="locations"
                            ref="locations"
                            class="lvl1-menu-item lg:mr-6 xl:mr-9"
                            :class="{ open: isLvl2PanelOpen.locations }"
                        >
                            <button
                                data-id="locations"
                                :class="{ 'nuxt-link-active': /^top-locations-/.test($route.name) }"
                                @click="toggleMenuPanel"
                            >
                                <span><T t="navigation.top_locations" /></span> <i class="pi pi-chevron-down"></i>
                            </button>
                            <div class="lvl1-menu-item__panel">
                                <div
                                    class="lg:container grid grid-cols-1 lg:grid-cols-5 gap-2 lg:gap-y-10 lg:gap-x-16 pb-4 lg:pb-0"
                                >
                                    <TopLocationMenuItemCard
                                        slug="house-of-hungarian-music"
                                        title-key="navigation.house_of_hungarian_music"
                                        static-image-key="house-of-hungarian-music___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="museum-of-ethnography"
                                        title-key="navigation.ethnography"
                                        static-image-key="ethnography___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="museum-of-fine-arts"
                                        title-key="navigation.museum_of_fine_arts"
                                        static-image-key="museum-of-fine-arts___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="house-of-the-hungarian-millennium"
                                        title-key="navigation.millennium"
                                        static-image-key="millennium___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="city-park-visitor-center"
                                        title-key="navigation.visitor_center"
                                        static-image-key="visitor-center___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="playground-of-varosliget"
                                        title-key="navigation.playground"
                                        static-image-key="playground___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="balloon-fly"
                                        title-key="navigation.balloon_fly"
                                        static-image-key="balloon-fly___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="varosliget-promenade"
                                        title-key="navigation.promenade"
                                        static-image-key="promenade___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="mocsenyi-mihaly-botanical-garden"
                                        title-key="navigation.mocsenyi"
                                        static-image-key="mocsenyi___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <TopLocationMenuItemCard
                                        slug="bird-friendly-learning-trail"
                                        title-key="navigation.bird_friendly_learning_trail"
                                        static-image-key="bird-friendly-learning-trail___hero"
                                        :is-image-displayed="isLvl2PanelOpen.locations || isMobileMenuOpen"
                                    />
                                    <div class="col-span-1 lg:col-span-5 bg-theme-dark hidden lg:block">
                                        <Button :to="localePath('map')" :label="$t('navigation.map_button_label')" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            id="visiting"
                            ref="visiting"
                            class="lvl1-menu-item"
                            :class="{ open: isLvl2PanelOpen.visiting }"
                        >
                            <button
                                data-id="visiting"
                                :class="{ 'nuxt-link-active': /^visitor-information-/.test($route.name) }"
                                @click="toggleMenuPanel"
                            >
                                <span><T t="navigation.visitor_information" /></span> <i class="pi pi-chevron-down"></i>
                            </button>
                            <div class="lvl1-menu-item__panel">
                                <div class="lg:container pb-4 lg:pb-0">
                                    <div class="grid grid-cols-12 base-responsive-gap">
                                        <div class="col-span-12 sm:col-span-6 lg:col-span-9">
                                            <div
                                                v-if="menuZones.HeaderVisitors?.menuItems.length"
                                                class="grid grid-cols-12 lg:grid-cols-9 base-responsive-gap"
                                            >
                                                <div
                                                    v-for="(item, i) in menuZones.HeaderVisitors.menuItems"
                                                    :key="`visitors-${item.id}`"
                                                    :class="
                                                        i % 2 === 0
                                                            ? 'col-span-12 lg:col-span-4 lg:col-start-1'
                                                            : 'col-span-12 lg:col-span-5'
                                                    "
                                                >
                                                    <a
                                                        v-if="item.new_window"
                                                        :href="item.url"
                                                        target="_blank"
                                                        class="lvl2-menu-item"
                                                    >
                                                        {{ item.title }}
                                                    </a>
                                                    <NuxtLink v-else :to="item.url" class="lvl2-menu-item">
                                                        <span>{{ item.title }}</span>
                                                    </NuxtLink>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                                            <NuxtLink :to="localePath('top-locations-balloon-fly')" class="block">
                                                <div class="aspect-w-4 aspect-h-3 base-responsive-rounding thumbnail">
                                                    <img
                                                        v-if="isLvl2PanelOpen.visiting"
                                                        :src="
                                                            $store.state.data.balloonStatus === 1
                                                                ? require('@/assets/images/balloon-fly/active.jpg')
                                                                : require('@/assets/images/balloon-fly/inactive.jpg')
                                                        "
                                                        :alt="$t('balloon_fly.title')"
                                                    />
                                                </div>
                                                <div class="font-Poppins font-bold text-lg mt-4">
                                                    <T t="balloon_fly.title" />
                                                </div>
                                                <div class="mt-2">
                                                    <T
                                                        :t="
                                                            $store.state.data.balloonStatus === 1
                                                                ? 'balloon_fly.open'
                                                                : 'balloon_fly.closed'
                                                        "
                                                    />
                                                </div>
                                            </NuxtLink>
                                            <Button
                                                :to="
                                                    localePath({
                                                        name: 'top-locations-balloon-fly',
                                                        hash: '#schedule',
                                                    })
                                                "
                                                :label="$t('generic.view')"
                                                class="mt-4"
                                            />
                                        </div>
                                        <div class="col-span-12 bg-theme-dark hidden lg:block">
                                            <Button
                                                :to="localePath('map')"
                                                :label="$t('navigation.map_button_label')"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {{/* DESKTOP - logo */}}
                    <NuxtLink
                        :to="localePath('index')"
                        class="hidden lg:block relative w-40 h-8 xl:w-52 xl:h-10 -mt-1.5 xl:-mt-3"
                        :aria-label="$t('navigation.homepage')"
                    >
                        <LigetLogo class="w-40 h-8 xl:w-52 xl:h-10" :white="dark" />
                    </NuxtLink>

                    <div class="lvl1-menu-group lvl1-menu-group--right">
                        <div class="lvl1-menu-item">
                            <NuxtLink
                                :to="localePath('the-project')"
                                :class="{ 'nuxt-link-active': /^the-project/.test(getRouteBaseName()) }"
                            >
                                <span>
                                    <T t="navigation.the_project" />
                                </span>
                            </NuxtLink>
                        </div>
                        <div class="lvl1-menu-item lg:ml-6 xl:ml-9">
                            <NuxtLink :to="localePath('magazine')">
                                <span>
                                    <T t="navigation.news" />
                                </span>
                            </NuxtLink>
                        </div>
                        <div class="lvl1-menu-item lg:ml-6 xl:ml-9">
                            <NuxtLink :to="localePath('map')">
                                <span>
                                    <T t="navigation.map" />
                                </span>
                            </NuxtLink>
                        </div>
                    </div>

                    {{/* MOBILE - social icons & language switcher */}}
                    <div class="flex lg:hidden items-center justify-between pt-8 pb-7">
                        <div class="flex lg:hidden items-center">
                            <SocialIcon
                                type="instagram"
                                small
                                url="https://www.instagram.com/liget_budapest/"
                                class="mx-2"
                            />
                            <SocialIcon type="facebook" small url="https://www.facebook.com/miligetunk/" class="mx-2" />
                            <SocialIcon
                                type="youtube"
                                small
                                url="https://www.youtube.com/channel/UCQnLn4FHzg7nj5LJ6w5voKA"
                                class="mx-2"
                            />
                            <SocialIcon
                                type="linkedin"
                                small
                                url="https://www.linkedin.com/company/75430226/"
                                class="mx-2"
                            />
                        </div>
                        <LanguageSwitcher />
                    </div>
                </div>
            </div>
        </nav>

        {{/* * */}}
        {{/* DESKTOP - language switcher & search button */}}
        {{/* * */}}
        <div class="hidden lg:flex absolute top-3 right-5 items-center z-2">
            <LanguageSwitcher class="mr-4" :theme-dark="dark" />
            <Button
                class="p-button-secondary p-button-text"
                :aria-label="$t('generic.open_searchbar')"
                icon="pi pi-search"
                @click.native="isSearchPanelOpen = true"
            ></Button>
        </div>

        {{/* * */}}
        {{/* DESKTOP - search panel */}}
        {{/* * */}}
        <div
            class="hidden lg:block fixed top-0 left-0 w-full h-full transition-opacity z-20"
            :class="isSearchPanelOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'"
        >
            <form
                class="absolute top-[calc(2rem+66px)] right-6 p-5 w-96 z-2 border"
                :class="dark ? 'bg-black border-white/20' : 'bg-white'"
                :style="`border-radius: 20px 0 20px 20px; top: calc(66px + ${announcementsCombinedHeight})`"
                @submit.prevent="search"
            >
                <div class="relative">
                    <InputText
                        ref="desktopSearchInput"
                        v-model="searchQuery"
                        :placeholder="$t('generic.search')"
                        class="desktop-search"
                    />
                    <div class="absolute top-1/2 right-0 -translate-y-1/2">
                        <Button
                            type="submit"
                            icon="pi pi-search"
                            class="p-button-text p-button-secondary"
                            :class="{ 'text-white': dark }"
                        />
                    </div>
                </div>
                <div class="text-center mt-4">
                    <Button type="submit" :label="$t('generic.search')" :aria-label="$t('generic.search')" />
                </div>
            </form>
            <div class="absolute top-0 left-0 w-full h-full bg-black/60 z-1" @click="closeSearchPanel"></div>
        </div>

        {{/* * */}}
        {{/* DESKTOP - menu background overlay */}}
        {{/* * */}}
        <div
            class="fixed top-0 left-0 w-full h-full bg-overlay-transparent z-1 hidden lg:block transition-opacity duration-[300ms]"
            :class="isMenuPanelBgOverlayVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'"
        ></div>
    </header>
</template>

<script>
import InputText from 'primevue/inputtext/InputText';
import HeaderProgramCard from '@/components/Cards/HeaderProgramCard/HeaderProgramCard.vue';
import TopLocationMenuItemCard from '@/components/Cards/TopLocationMenuItemCard/TopLocationMenuItemCard.vue';
import SocialIcon from '@/components/Generic/SocialIcon/SocialIcon.vue';
import LigetLogo from '@/components/SVGs/LigetLogo.vue';
import announcement from '@/mixins/announcement.js';
import events from '@/mixins/events.js';
import LanguageSwitcher from '@/components/Layout/LanguageSwitcher.vue';

export default {
    name: 'Header',
    components: {
        LanguageSwitcher,
        SocialIcon,
        HeaderProgramCard,
        TopLocationMenuItemCard,
        InputText,
        LigetLogo,
    },
    mixins: [announcement, events],
    props: {
        dark: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            searchQuery: '',
            isLanguageDropdownOpen: false,
            isLvl2PanelOpen: {
                program: false,
                locations: false,
                visiting: false,
            },
            isMenuPanelBgOverlayVisible: false,
            doMenuPanelsHaveTransition: true,
            isSearchPanelOpen: false,
            isMobileMenuOpen: false,
        };
    },
    computed: {
        publicAnnouncementsToDisplay() {
            return this.$store.getters['data/getPublicAnnouncementsToDisplay'];
        },
        menuZones() {
            return this.$store.getters['data/getMenuZones'];
        },
    },
    watch: {
        '$i18n.locale'() {
            this.isMobileMenuOpen = false;
        },
        isSearchPanelOpen(value) {
            if (value) {
                this.$refs.desktopSearchInput.$el.focus();
            }
        },
        //     $route() {
        //         this.closeMenuPanels();
        //         this.isMobileMenuOpen = false;
        //     },
    },
    mounted() {
        document.querySelector('body').addEventListener('click', (e) => {
            if (!e.target.closest('.lvl1-menu-item')) {
                this.closeMenuPanels();
            }
        });

        Array.from(document.querySelectorAll('header a')).forEach((anchor) => {
            anchor.addEventListener('click', () => {
                this.doMenuPanelsHaveTransition = true;

                this.$nextTick(() => {
                    this.isMobileMenuOpen = false;
                    this.closeMenuPanels();
                });
            });
        });
    },
    methods: {
        toggleMenuPanel(e) {
            if (window.innerWidth < 1024) {
                // BEHAVIOR ON MOBILE & TABLET

                const panel = e.target.nextElementSibling;

                if (this.isLvl2PanelOpen[e.target.parentElement.id]) {
                    // If user clicked on an accordion header that's panel is already open, close it
                    panel.style.maxHeight = 0;
                    this.isLvl2PanelOpen[e.target.parentElement.id] = false;
                } else {
                    // Else, first reset (close all other panels)
                    Object.keys(this.isLvl2PanelOpen).forEach((key) => {
                        this.isLvl2PanelOpen[key] = false;
                        this.$refs[key].firstElementChild.nextElementSibling.style.transitionDuration = '0s';
                        this.$refs[key].firstElementChild.nextElementSibling.style.maxHeight = 0;
                    });

                    // Then open just that panel
                    panel.style.maxHeight = 1000 + 'px';
                    panel.style.transitionDuration = '.5s';
                    this.isLvl2PanelOpen[e.target.parentElement.id] = true;
                    setTimeout(() => {
                        if (this.isLvl2PanelOpen[e.target.parentElement.id]) {
                            panel.style.maxHeight = 'auto';
                            panel.style.transitionDuration = `${panel.scrollHeight}ms`;
                        }
                    }, 500);
                }
            } else {
                // BEHAVIOR ON DESKTOP

                // remove if you wanna restore lvl2 panel opening on hover

                // eslint-disable-next-line
                if (this.isLvl2PanelOpen[e.target.getAttribute('data-id')]) {
                    this.$refs[e.target.getAttribute('data-id')].classList.remove('open');
                    this.isLvl2PanelOpen[e.target.getAttribute('data-id')] = false;
                    this.isMenuPanelBgOverlayVisible = false;
                } else {
                    Object.keys(this.isLvl2PanelOpen).forEach((key) => {
                        this.isLvl2PanelOpen[key] = false;
                    });

                    this.$refs.program.classList.remove('open');
                    this.$refs.locations.classList.remove('open');
                    this.$refs.visiting.classList.remove('open');

                    this.isLvl2PanelOpen[e.target.getAttribute('data-id')] = true;

                    this.isMenuPanelBgOverlayVisible = true;

                    this.$refs[e.target.getAttribute('data-id')].classList.add('open');
                }

                setTimeout(() => {
                    if (this.isMenuPanelBgOverlayVisible) {
                        this.doMenuPanelsHaveTransition = false;
                    }
                }, 300);
            }
        },
        closeMenuPanels() {
            this.doMenuPanelsHaveTransition = true;
            this.$nextTick(() => {
                if (this.$refs.program) {
                    this.$refs.program.classList.remove('open');
                }
                if (this.$refs.locations) {
                    this.$refs.locations.classList.remove('open');
                }
                if (this.$refs.visiting) {
                    this.$refs.visiting.classList.remove('open');
                }

                Object.keys(this.isLvl2PanelOpen).forEach((key) => {
                    this.isLvl2PanelOpen[key] = false;
                });

                this.isMenuPanelBgOverlayVisible = false;
            });
        },
        onHoverAreaMouseEnter(e) {
            if (window.innerWidth < 1024) {
                return;
            }
            this.isLvl2PanelOpen[e.target.id] = true;
            setTimeout(() => {
                if (this.isLvl2PanelOpen[e.target.id]) {
                    this.isMenuPanelBgOverlayVisible = true;
                    e.target.classList.add('open');
                }
            }, 50);
        },
        onHoverAreaMouseLeave(e) {
            if (window.innerWidth < 1024) {
                return;
            }
            this.isLvl2PanelOpen[e.target.id] = false;
            this.isMenuPanelBgOverlayVisible = false;
            e.target.classList.remove('open');
        },
        search() {
            const q = this.searchQuery;

            this.isMobileMenuOpen = false;
            this.closeSearchPanel();
            this.$router.push(this.localePath({ name: 'search', query: { q } }));
        },
        closeSearchPanel() {
            this.isSearchPanelOpen = false;
            this.searchQuery = '';
        },
        // closeAllNavPanels() {
        //     this.isMobileMenuOpen = false;
        //     this.closeMenuPanels();
        // },
    },
};
</script>

<style>
.lvl1-menu-group {
    @apply lg:w-[calc(50%-5rem)] flex flex-col lg:flex-row lg:items-center;
    &--left {
        @apply lg:justify-end lg:pr-[42px] xl:pr-[40px] 2xl:pr-[92px];
    }
    &--right {
        @apply lg:justify-start lg:pl-[42px] xl:pl-[40px] 2xl:pl-[92px];
    }
}

.lvl1-menu-item {
    @apply static border-b border-black lg:border-none;
    > a,
    > button {
        @apply font-Poppins font-medium text-sm text-left uppercase;
        @apply flex items-center justify-between lg:justify-start w-full lg:w-auto py-5 lg:py-6;

        i {
            @apply text-sm lg:ml-2 transition-transform;
        }
    }

    > a span,
    > button span {
        position: relative;
        &::after {
            content: '';
            display: none;
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            @apply bg-yellow-400;
        }
    }
    > a,
    button {
        span,
        i {
            pointer-events: none;
        }
    }
}

.bg-theme-dark {
    .lvl1-menu-item {
        > a,
        > button {
            @apply lg:text-white;
        }
    }
}

.lvl1-menu-item__panel {
    transition-property: max-height;
    transition-timing-function: ease-out;
    @apply max-h-0 lg:max-h-[unset];
    @apply lg:absolute lg:w-screen lg:top-16 lg:left-1/2 lg:-translate-x-1/2 lg:pt-14 lg:pb-16;
    @apply lg:opacity-0 lg:pointer-events-none overflow-hidden bg-gray-200 lg:rounded-br-4xl lg:rounded-bl-4xl;
    @apply text-black;
    @media (min-width: 1024px) {
        max-height: unset !important;
    }
}

.transition-panels .lvl1-menu-item__panel {
    @apply lg:transition-opacity;
}

.lvl1-menu-item.open {
    i {
        @apply -rotate-180;
    }
    .lvl1-menu-item__panel {
        @apply lg:opacity-100 lg:pointer-events-auto;
    }
}

.lvl1-menu-item.open > button span,
.lvl1-menu-item:hover > button span,
.lvl1-menu-item:hover > a span,
.lvl1-menu-item > .nuxt-link-active > span {
    &::after {
        @apply lg:block;
    }
}

.lvl2-menu-item {
    @apply inline-block mb-4 last:mb-0;
    span {
        @apply font-Poppins font-semibold text-lg hover:underline;
    }
}

.desktop-search {
    border-width: 0 0 1px 0;
    @apply border-black rounded-none pr-8 pl-0 py-1;
}
</style>
