export default {
    watch: {
        $route(from, to) {
            this.$i18n.finalizePendingLocaleChange();
        },
        '$i18n.locale'(value) {
            this.$primevue.config.locale = this.$primevue.config.locales[value];
        },
    },
    mounted() {
        this.$primevue.config.locale = this.$primevue.config.locales[this.$i18n.locale];
    },
};
