<template>
    <figure>
        <div :class="wrapperClass">
            <picture>
                <source
                    :media="`(min-width:${fullScreen ? 992 : 1440}px)`"
                    :srcset="maxSize === 'lg' ? model.srcSet.lg : model.srcSet[maxSize]"
                />
                <source
                    :media="`(min-width:${fullScreen ? 1 : 992}px)`"
                    :srcset="maxSize === 'lg' ? model.srcSet.md : model.srcSet[maxSize]"
                />
                <img :src="model.srcSet.sm" :alt="model.alt" :title="model.title" :class="imageClass" />
            </picture>
        </div>
        <figcaption v-if="model.caption" class="relative inline-block text-sm mt-2 lg:mt-3 ml-5">
            {{ model.caption }}
        </figcaption>
    </figure>
</template>

<script>
export default {
    name: 'StaticImage',
    props: {
        imgKey: {
            type: String,
            required: true,
        },
        maxSize: {
            type: String,
            required: false,
            default: 'lg',
            validator(value) {
                if (!['sm', 'md', 'lg'].includes(value)) {
                    throw new Error('Invalid static image size');
                }
                return true;
            },
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        imageClass: {
            type: String,
            required: false,
            default: null,
        },
        wrapperClass: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        model() {
            return (
                this.$store.state.data.staticImages[this.imgKey] || {
                    srcSet: {
                        sm: '',
                        md: '',
                        lg: '',
                    },
                }
            );
        },
    },
};
</script>

<style scoped></style>
