<template>
    <message :t="t" :tc="tc[0]">
        {{ tc.length ? $tc(tc[0], tc[1], tc[2]) : $t(t) }}
    </message>
</template>

<script>
import Message from '@/components/UI/T/Message';

export default {
    name: 'T',
    components: {
        Message,
    },
    props: {
        t: {
            type: String,
            required: false,
            default: '',
        },
        tc: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>

<style scoped></style>
