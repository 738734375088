<template>
    <article class="anchor-card h-full">
        <a :href="url" target="_blank" class="grid grid-cols-1 gap-2">
            <div class="thumbnail base-responsive-rounding aspect-h-2 aspect-w-3 bg-white">
                <img v-if="isImageDisplayed || !$store.state.layout.isImageLoadingBlocked" :src="img" :alt="title" />
            </div>
            <div>
                <div class="text-gray-400 font-bold uppercase text-xs sm:text-sm my-2">
                    <span v-if="date">
                        {{ date }}
                    </span>
                    <span v-if="tag" class="inline">
                        <span class="separator-bullet"></span>
                        {{ tag }}
                    </span>
                </div>
                <h3 class="font-Poppins text-base sm:text-lg font-semibold sm:line-clamp-2">
                    {{ title }}
                </h3>
            </div>
        </a>
    </article>
</template>

<script>
export default {
    name: 'ArticleCard',
    props: {
        url: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        img: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: false,
            default: null,
        },
        tag: {
            type: String,
            required: false,
            default: null,
        },
        isImageDisplayed: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped></style>
