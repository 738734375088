export default function ({ i18n, $axios, $config, $dayjs, store }) {
    // This method will generate the localized route data for a given dynamic endpage and
    // dynamically add them to the i18n.pages object in nuxt.config.js.

    // reference: https://i18n.nuxtjs.org/v7/lang-switcher

    i18n.generateLocalizedRouteParams = async function (variants, paramName = 'slug') {
        const _routeParamsPayload = {};

        i18n.locales.forEach((locale) => {
            _routeParamsPayload[locale.code] = {};
        });

        i18n.locales.forEach((locale) => {
            if (variants[locale.code]) {
                _routeParamsPayload[locale.code][paramName] = variants[locale.code];
            }
        });

        await store.dispatch('i18n/setRouteParams', _routeParamsPayload);
    };

    i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
        // console.log('before l switch');
        //     console.log(oldLocale, newLocale, isInitialSetup)
    };

    i18n.onLanguageSwitched = (oldLocale, newLocale) => {
        $axios.defaults.baseURL = $config.apiBaseURL + '/api/' + newLocale;
        $dayjs.locale(newLocale);
        store.dispatch('fetchEssentialData');
    };
}
