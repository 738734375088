import createPersistedState from 'vuex-persistedstate';

export default ({ store, $cookies }) => {
    // Only persist stuff in cookies that you need the server side logic to have access to, (e.g. access token or something)
    // and make sure the combined size of the cookies will not exceed 4Kb.
    // Otherwise, if client only storage is fine, register a persisted-state.client.js instead and call createPersistedState()
    // without "storage" defined. It will store your data in local storage by default.

    createPersistedState({
        key: 'vuex_data',
        paths: ['dev'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value);
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);

    createPersistedState({
        key: 'dismissed-alerts',
        paths: ['data.dismissedPublicAnnouncementIDs'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value, {
                    path: '/',
                    maxAge: 60 * 60 * 24,
                });
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);
};
