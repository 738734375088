<template>
    <div>
        <PublicAnnouncement
            v-for="(announcement, i) in publicAnnouncementsToDisplay"
            :id="announcement.id"
            :key="`announcement-${i}`"
            :class="i > 0 ? 'border-t border-white/50' : ''"
            :style="`top: ${i * ANNOUNCEMENT_BAR_HEIGHT}rem; height: ${ANNOUNCEMENT_BAR_HEIGHT}rem`"
            :text="announcement.title"
            :url="announcement.url"
        >
        </PublicAnnouncement>
    </div>
</template>

<script>
import PublicAnnouncement from '@/components/Layout/PublicAnnouncement.vue';
import announcement from '@/mixins/announcement';

export default {
    name: 'PublicAnnouncements',
    components: {
        PublicAnnouncement,
    },
    mixins: [announcement],
    computed: {
        publicAnnouncementsToDisplay() {
            return this.$store.getters['data/getPublicAnnouncementsToDisplay'];
        },
    },
};
</script>

<style scoped></style>
