export const state = () => ({
    visitorInformationLayoutTheme: 'blue',
    isImageLoadingBlocked: true,
});

export const mutations = {
    setVisitorInformationLayoutTheme(state, payload) {
        if (!/(blue|yellow)/.test(payload)) {
            console.warn('Invalid visitor information layout theme set. Setting theme to blue');
            state.visitorInformationLayoutTheme = 'blue';
        }

        state.visitorInformationLayoutTheme = payload;
    },
    setIsImageLoadingBlocked(state, payload) {
        state.isImageLoadingBlocked = payload;
    },
};
