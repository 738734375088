<template>
    <div class="fixed top-0 left-0 w-full bg-error text-white">
        <div ref="container" class="container flex items-center justify-between h-full">
            <div ref="marquee" class="overflow-hidden flex" style="width: calc(100% - 2rem)">
                <div class="whitespace-nowrap" :class="{ 'pl-32 md:pl-52 lg:pl-96': isMarqueeInitialized }">
                    <a v-if="url" :href="url" target="_blank">
                        <div ref="text" class="flex">
                            {{ text }}
                        </div>
                    </a>

                    <div v-else ref="text">
                        {{ text }}
                    </div>
                </div>
            </div>

            <a v-if="url" :href="url" target="_blank">
                <div class="underline mx-4"><T t="public_announcements.button_label" /></div>
            </a>

            <div class="inline-flex items-center justify-center min-w-[1.5rem]">
                <Button
                    btn-close
                    class="p-button-sm"
                    style="padding: 0.25rem; font-size: 0.5rem"
                    @click.native="() => dismiss(id)"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PublicAnnouncement',
    props: {
        id: {
            type: Number,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isMarqueeInitialized: false,
        };
    },
    mounted() {
        if (!this.$refs.text) {
            return;
        }
        if (this.$refs.text.getBoundingClientRect().width > this.$refs.container.getBoundingClientRect().width) {
            this.isMarqueeInitialized = true;
            this.$nextTick(() => {
                this.marquee(this.$refs.marquee, 1);
            });
        }
    },
    methods: {
        marquee(marqueeWrapper, speed) {
            const clone = marqueeWrapper.innerHTML;
            const firstElement = marqueeWrapper.children[0];
            let i = 0;
            marqueeWrapper.insertAdjacentHTML('beforeend', clone);
            marqueeWrapper.insertAdjacentHTML('beforeend', clone);
            marqueeWrapper.insertAdjacentHTML('beforeend', clone);

            setInterval(() => {
                firstElement.style.marginLeft = `-${i}px`;
                if (i > firstElement.clientWidth) {
                    i = 0;
                }
                i = i + speed;
            }, 16);
        },

        dismiss(id) {
            if (!this.$store.state.data.publicAnnouncements.find((x) => x.id === id)) {
                throw new Error('Invalid public announcement id');
            }

            this.$store.commit('data/setPublicAnnouncementToDismissed', id);
        },
    },
};
</script>

<style scoped></style>
