export default {
    computed: {
        EVENT_CATEGORIES() {
            const categories = this.$store.state.data.eventCategories;

            return {
                CULTURAL: categories.find((c) => c.id === 1),
                OUTDOOR: categories.find((c) => c.id === 2),
                FAMILY: categories.find((c) => c.id === 3),
                SPORT: categories.find((c) => c.id === 4),
            };
        },
    },
};
