export const state = () => ({
    windowWidth: 0,
    scrollY: 0,
});

export const getters = {
    getWindowWidth(state) {
        return state.windowWidth;
    },
};

export const mutations = {
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
};

export const actions = {
    async fetchEssentialData(context) {
        await Promise.allSettled([
            this.$axios.$get('/page-metadata'),
            this.$axios.$get('/public-announcements'),
            this.$axios.$get('/events/categories/event-categories'),
            this.$axios.$get('/events/categories/event-types'),
            this.$axios.$get('/events/locations/event-locations'),
            this.$axios.$get(`/events?highlighted=1&start_date=${this.$dayjs().format('YYYY-MM-DD')}&limit=7`),
            this.$axios.$get('/news-categories'),
            this.$axios.$get('/static-images'),
            this.$axios.$get('/menus'),
            this.$axios.$get('/balloon'),
            this.$axios.$get('/home-carousels'),
            this.$axios.$get('/news?is_highlighted=1&limit=3'),
            this.$axios.$get('/redirects'),
            this.$axios.$get('/qr-codes'),
        ])
            .then(
                ([
                    metaRes,
                    publicAnnouncementsRes,
                    eventCategoriesRes,
                    eventTypesRes,
                    eventLocationsRes,
                    recommendedEventsRes,
                    newsCategoriesRes,
                    staticImagesRes,
                    menuZonesRes,
                    balloonRes,
                    homepagePromotionGalleryItemsRes,
                    homepageHighlightedNewsRes,
                    redirectsRes,
                    qrCodeRedirectRes,
                ]) => {
                    if (metaRes.status === 'fulfilled') {
                        context.commit('data/setMetaData', {
                            data: metaRes.value,
                        });
                    }

                    if (publicAnnouncementsRes.status === 'fulfilled') {
                        context.commit('data/setPublicAnnouncements', {
                            data: publicAnnouncementsRes.value.data,
                        });
                    }

                    if (eventCategoriesRes.status === 'fulfilled') {
                        context.commit('data/setEventCategories', {
                            data: eventCategoriesRes.value,
                        });
                    }

                    if (eventTypesRes.status === 'fulfilled') {
                        context.commit('data/setEventTypesByCategory', {
                            data: eventTypesRes.value,
                        });
                    }

                    if (eventLocationsRes.status === 'fulfilled') {
                        context.commit('data/setEventLocations', {
                            data: eventLocationsRes.value,
                        });
                    }

                    if (recommendedEventsRes.status === 'fulfilled') {
                        context.commit('data/setRecommendedEvents', {
                            data: recommendedEventsRes.value.data,
                        });
                    }

                    if (newsCategoriesRes.status === 'fulfilled') {
                        context.commit('data/setNewsCategories', {
                            data: newsCategoriesRes.value.data,
                        });
                    }

                    if (staticImagesRes.status === 'fulfilled') {
                        context.commit('data/setStaticImages', {
                            data: staticImagesRes.value,
                        });
                    }

                    if (menuZonesRes.status === 'fulfilled') {
                        context.commit('data/setMenuZones', {
                            data: menuZonesRes.value,
                        });
                    }

                    if (balloonRes.status === 'fulfilled') {
                        context.commit('data/setBalloonStatus', {
                            data: balloonRes.value.balloon_is_up,
                        });
                    }

                    if (homepagePromotionGalleryItemsRes.status === 'fulfilled') {
                        context.commit('data/setHomepagePromotionGalleryItems', {
                            data: homepagePromotionGalleryItemsRes.value.carousels,
                        });
                    }

                    if (homepageHighlightedNewsRes.status === 'fulfilled') {
                        context.commit('data/setHomepageHighlightedNews', {
                            data: homepageHighlightedNewsRes.value.data,
                        });
                    }

                    if (redirectsRes.status === 'fulfilled') {
                        context.commit('data/setRedirects', {
                            data: redirectsRes.value.data,
                        });
                    }

                    if (qrCodeRedirectRes.status === 'fulfilled') {
                        context.commit('data/setQrCodeRedirects', {
                            data: qrCodeRedirectRes.value.data,
                        });
                    }
                }
            )
            .catch((err) => {
                console.log(err);
            });
    },
    async nuxtServerInit({ dispatch }, { req }) {
        await dispatch('fetchEssentialData');
    },
};
