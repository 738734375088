<template>
    <div class="bg-gray-200">
        <div class="container">
            <div class="grid grid-cols-12">
                <div
                    class="col-span-12 md:col-span-8 md:col-start-3 text-center py-40 min-h-[calc(100vh-6.875rem)] flex flex-col items-center justify-center"
                >
                    <h2 class="text-green-500 display font-bold mb-4">{{ error.statusCode }}</h2>
                    <h1 v-if="error.statusCode === 404" class="header-3"><T t="errors.page_not_found" /></h1>
                    <h2 v-else>{{ $t('errors.an_error_occurred') }}</h2>
                    <Button :to="localePath('/')" :label="$t('errors.back_to_homepage')" class="mt-8" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',
    layout: 'default',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
};
</script>
