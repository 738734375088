import Vue from 'vue';

import InputError from '@/components/UI/Inputs/InputError.vue';
import Button from '@/components/UI/Button.vue';
import SeparatorLine from '@/components/UI/SeparatorLine.vue';
import StaticImage from '@/components/UI/StaticImage.vue';
import Breadcrumb from '@/components/Generic/Breadcrumb.vue';
import T from '@/components/UI/T/T.vue';

Vue.component('InputError', InputError);
Vue.component('Button', Button);
Vue.component('SeparatorLine', SeparatorLine);
Vue.component('StaticImage', StaticImage);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('T', T);
