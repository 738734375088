import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { MapControls } from 'three/examples/jsm/controls/MapControls.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { Box3 } from 'three/src/math/Box3';
import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { PCFSoftShadowMap } from 'three';
import { gsap } from 'gsap';

export default ({ app }, inject) => {
    inject('THREE', THREE);
    inject('PCFSoftShadowMap', PCFSoftShadowMap);
    inject('GLTFLoader', GLTFLoader);
    inject('OrbitControls', OrbitControls);
    inject('MapControls', MapControls);
    inject('DRACOLoader', DRACOLoader);
    inject('KTX2Loader', KTX2Loader);
    inject('Box3', Box3);
    inject('SimplifyModifier', SimplifyModifier);
    inject('MeshoptDecoder', MeshoptDecoder);
    inject('gsap', gsap);
};
