export const state = () => ({
    areTranslationKeysVisible: false,
    isGridOverlayVisible: false,
});

export const mutations = {
    setIsGridOverlayVisible(state, payload) {
        state.isGridOverlayVisible = payload;
    },
    setAreTranslationKeysVisible(state, payload) {
        state.areTranslationKeysVisible = payload;
    },
};
