export default ({ app, $dayjs, store, i18n, localePath, $device }, inject) => {
    inject('clone', (obj) => {
        return JSON.parse(JSON.stringify(obj));
    });
    inject('scrollToElement', (selector, offset = 150, mode = 'smooth') => {
        const $element = document.querySelector(selector);

        if (!$element) {
            return;
        }

        const targetScrollY =
            $element.getBoundingClientRect().top -
            store.getters['data/getPublicAnnouncementsToDisplay'].length * 16 * 3 +
            window.scrollY -
            offset;

        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ left: 0, top: targetScrollY, behavior: mode });
        } else {
            window.scrollTo(0, targetScrollY);
        }
    });
    inject('downloadFile', (url, fileName) => {
        fetch(url, { method: 'get', referrerPolicy: 'no-referrer' })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', fileName);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            });
    });

    // This method was introduced because:
    // 1. the thumbnail image is not always required & the hero image can be used for it as fallback
    // 2. there is no naming convention for the hero & thumbnail image props, they are inconsistent
    inject('getMediaImage', (media, type, size = $device.isMobile ? 'sm' : 'md') => {
        if (!/^(hero|lead|image)$/.test(type)) {
            throw new Error('Invalid media type');
        }

        if (!/^(sm|md|lg)$/.test(size)) {
            throw new Error('Invalid media image size');
        }

        if (typeof media !== 'object' || Array.isArray(media) || media === null) {
            console.error('Invalid media value type');
            return '';
        }

        if (media.image && type === 'image') {
            return media.image[size];
        }

        if (media.lead && type === 'lead') {
            return media.lead[size];
        }

        if (media.lead_image && type === 'lead') {
            return media.lead_image[size];
        }

        if (media.hero && /^(lead|hero)$/.test(type)) {
            return media.hero[size];
        }

        if (media.hero_image && /^(lead|hero)$/.test(type)) {
            return media.hero_image[size];
        }

        if (media.header_image && /^(lead|hero)$/.test(type)) {
            return media.header_image[size];
        }

        return '/og-image.png';
    });
    inject('generateDateFilterOptions', () => {
        const dayIndex = $dayjs().day();

        return [
            {
                id: 1,
                label: i18n.t('time.any'),
                value: 'any',
            },
            {
                id: 2,
                label: i18n.t('time.today'),
                value: `${$dayjs().format('YYYY-MM-DD')}_${$dayjs().format('YYYY-MM-DD')}`,
            },
            {
                id: 3,
                label: i18n.t('time.tomorrow'),
                value: `${$dayjs().add(1, 'day').format('YYYY-MM-DD')}_${$dayjs().add(1, 'day').format('YYYY-MM-DD')}`,
            },
            // {
            //     id: 4,
            //     label: 'A héten',
            //     value: `${$dayjs().format('YYYY-MM-DD')}_${$dayjs()
            //         .add(7 - dayIndex, 'day')
            //         .format('YYYY-MM-DD')}`,
            // },
            {
                id: 5,
                label: i18n.t('time.this_weekend'),
                value: `${$dayjs()
                    .add(7 - dayIndex - 1, 'day')
                    .format('YYYY-MM-DD')}_${$dayjs()
                    .add(7 - dayIndex, 'day')
                    .format('YYYY-MM-DD')}`,
            },
            // {
            //     id: 6,
            //     label: 'Jövő héten',
            //     value: `${$dayjs()
            //         .add(7 - dayIndex + 1, 'day')
            //         .format('YYYY-MM-DD')}_${$dayjs()
            //         .add(7 - dayIndex + 7, 'day')
            //         .format('YYYY-MM-DD')}`,
            // },
        ];
    });
    inject('getNewsCategoryLabel', (id) => {
        if (!id || !store.state.data.newsCategories.length) {
            return null;
        }
        return store.state.data.newsCategories.find((x) => x.id === id)?.name || '';
    });
    inject('getFormattedEventInterval', (event) => {
        if (event.text_date_only && event.date) {
            return event.date;
        }

        if (event.exhibition && event.constant) {
            return i18n.t('programs_in_varosliget.constant_exhibition');
        }

        if (event.exhibition && event.periodic) {
            return i18n.t('programs_in_varosliget.periodic_exhibition');
        }

        const $startDate = $dayjs(event.start_date);
        const $endDate = $dayjs(event.end_date);

        if ($startDate && !$startDate.isValid()) {
            console.error('Invalid date');
            return null;
        }

        // If event is NOT periodic or constant, we only need to return a single date, which is the start date
        if (!event.constant && !event.periodic) {
            let ret = '';

            if (!$dayjs().isSame($startDate, 'year')) {
                ret += $startDate.format('YYYY');
            }

            ret += ' ' + $startDate.format('MMM.').toUpperCase();
            ret += ' ' + $startDate.format('D. ddd.');

            if ($startDate.hour() !== 0 || $startDate.minute() !== 0) {
                ret += ' ' + $startDate.format('HH:mm');
            }

            return ret;
        }

        // Else, we return an interval
        let ret = '';

        if (!$dayjs().isSame($startDate, 'year')) {
            ret += $startDate.format('YYYY');
        }

        ret += ' ' + $startDate.format('MMM.').toUpperCase();
        ret += ' ' + $startDate.format('D.');

        // if ($startDate.hour() !== 0 || $startDate.minute() !== 0) {
        //     ret += ' ' + $startDate.format('HH:mm');
        // }

        if ($endDate && $endDate.isValid()) {
            ret += ' -';
            if (!$dayjs($startDate).isSame($endDate, 'year')) {
                ret += $endDate.format('YYYY');
            }

            if (!$dayjs($startDate).isSame($endDate, 'month')) {
                ret += ' ' + $endDate.format('MMM.').toUpperCase();
            }
            if (!$dayjs($startDate).isSame($endDate, 'day')) {
                ret += ' ' + $endDate.format('D.');
            }

            // if ($endDate.hour() !== 0 || $endDate.minute() !== 0) {
            //     ret += ' ' + $endDate.format('HH:mm');
            // }
        }

        return ret;
    });
    inject('getEventLocationName', (eventLocationID) => {
        return store.state.data.eventLocations.find((x) => x.id === eventLocationID)?.title;
    });
    inject('getEventUrl', (event) => {
        if (event.url) {
            return event.url;
        }

        // if (event.series) {
        //     return localePath({ name: 'program-series-programSeries', params: { programSeries: event.series.slug } });
        // }

        return localePath({ name: 'programs-program', params: { program: event.slug } });
    });
    inject('getPageTitle', (data) => {
        let _metaData;

        if (typeof data === 'string') {
            _metaData = store.state.data.metaData.find((x) => x.key === data);
        } else {
            _metaData = data;
        }

        if (!_metaData) {
            return null;
        }

        return _metaData.title;
    });
    inject('getPageMetaData', (data) => {
        let _metaData;

        if (typeof data === 'string') {
            _metaData = store.state.data.metaData.find((x) => x.key === data);
        } else {
            _metaData = data;
        }

        if (!_metaData) {
            return [];
        }

        const ret = [];

        if (_metaData.title) {
            ret.push({ hid: 'title', name: 'title', content: _metaData.title });
            ret.push({ hid: 'og:title', name: 'og:title', content: _metaData.title });
        }

        if (_metaData.description) {
            ret.push({ hid: 'description', name: 'description', content: _metaData.description });
            ret.push({ hid: 'og:description', name: 'og:description', content: _metaData.description });
        }

        if (_metaData.image) {
            ret.push({ hid: 'og:image', name: 'og:image', content: _metaData.image });
            ret.push({ hid: 'og:image:url', name: 'og:image:url', content: _metaData.image });
        }

        return ret;
    });
};
