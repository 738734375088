<template>
    <NuxtLink
        :to="localePath(`top-locations-${slug}`)"
        class="top-locations-menu-item-card anchor-card flex flex-row lg:flex-col items-center lg:items-start"
    >
        <div class="thumbnail w-[2.5rem] min-w-[2.5rem] h-[2rem] lg:w-full lg:h-auto lg:mb-3">
            <StaticImage
                v-if="isImageDisplayed || !$store.state.layout.isImageLoadingBlocked"
                :img-key="staticImageKey"
                wrapper-class="aspect-w-3 aspect-h-2 rounded-md lg:rounded-[30px] overflow-hidden"
            />
        </div>
        <span class="top-locations-menu-item-card__title font-Poppins font-semibold ml-2 lg:ml-0">
            <T :t="titleKey" />
        </span>
    </NuxtLink>
</template>

<script>
export default {
    name: 'TopLocationMenuItemCard',
    props: {
        slug: {
            type: String,
            required: true,
        },
        titleKey: {
            type: String,
            required: true,
        },
        staticImageKey: {
            type: String,
            required: true,
        },
        isImageDisplayed: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style>
/*
.top-locations-menu-item-card.nuxt-link-exact-active .top-locations-menu-item-card__title span {
    @apply underline;
}
*/
</style>
