export default function ({ $axios, i18n, $config, error }) {
    $axios.interceptors.request.use((request) => {
        return request;
    });

    // $axios.interceptors.response.use((response) => {
    //     return response;
    // });

    $axios.onError((err) => {
        if ($config.nuxtENV !== 'production') {
            console.log(err.response.status);
            console.log(err.message);
        }

        if (err.response.status !== 404) {
            return error({ statusCode: err.response.status });
        }
    });

    $axios.defaults.baseURL = $config.apiBaseURL + '/api/' + i18n.locale;
}
