import Vue from 'vue';

const outsideClickHandlers = [];

Vue.directive('closable', {
    bind(el, binding, vnode) {
        outsideClickHandlers.unshift((e) => {
            e.stopPropagation();
            const ref = binding.value;
            const handler = ref.handler;
            let exclude = ref.exclude;

            if (!exclude) {
                exclude = [];
            }

            let clickedOnExcludedEl = false;

            exclude.forEach((refName) => {
                if (!clickedOnExcludedEl) {
                    let excludedEl = vnode.context.$refs[refName];
                    excludedEl = excludedEl instanceof Vue ? excludedEl.$el : excludedEl;
                    if (excludedEl) {
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                }
            });

            let hasElementExceptionClass = null;
            if (ref.classExceptions) {
                hasElementExceptionClass = ref.classExceptions.find((className) => {
                    return e.target.classList.contains(className);
                });
            }

            if (!el.contains(e.target) && !hasElementExceptionClass && !clickedOnExcludedEl) {
                vnode.context[handler]();
            }
        });
        document.addEventListener('click', outsideClickHandlers[0]);
    },

    unbind() {
        document.removeEventListener('click', outsideClickHandlers[0]);
        outsideClickHandlers.shift();
    },
});

Vue.directive('autofocus', {
    // When the bound element is inserted into the DOM...
    inserted(el) {
        // Focus the element
        if (window.innerWidth >= 768) {
            el.focus();
        }
    },
});
